<template>

  <div id="item-options-revised">

    <div class="close-box" @click="exit()" v-if="subMenuSelected() === false">
      <img src="../assets/closedialogue.svg" />
    </div>

    <div class="items-selected-list" v-if="selected_items.length > 0">

      <div class="selected-items-container">

        <div v-for="selected_item in selected_item_details" :key="selected_item.id" class="selected-item">
          {{ selected_item['translation_' + language] || selected_item.translation_en || selected_item.name }}
        </div>

      </div>

      <div class="explainer-text-small" v-if="selected_items.length > 1">Changes will apply to all selected items</div>

    </div>

    <div class="buttons-container" v-if="subMenuSelected() === false && show_more_options === false">

      <div class="button" v-for="button in computedButtons()" :key="button.name" @click="handleEvent(button.name)">

        <img :src="require(`../assets/${button.imgUrl}`)" v-if="button.imgUrl != undefined" />

        <p>{{ button.name }}</p>

      </div>

    </div>

    <div class="extra-buttons" v-if="show_more_options === true">
      <div @click="toggleAlignment('left')">
        <i class="pi pi-align-left" style="font-size: 25px; color: black"></i>
        <p>Align left</p>
      </div>

      <div @click="toggleAlignment('centre')">
        <i class="pi pi-align-center" style="font-size: 25px; color: black"></i>
        <p>Align centre</p>
      </div>

      <div @click="relocateMultipleItems()">
        <i class="pi pi-sort" style="font-size: 25px; color: black"></i>
        <p>Bulk sort items</p>
      </div>

      <div @click="copyItemsToOtherMenu()">
        <i class="pi pi-copy" style="font-size: 25px; color: black"></i>
        <p>Copy items</p>
      </div>
    </div>

    <CopyItemsToOtherMenuModal v-if="copy_items_to_other_menu === true" :selected_items="selected_items"
      v-on:close="closeModal()" />

    <div class="more-options" @click="show_more_options = !show_more_options">{{ moreOptionsText() }}<span
        class="new-identifier"
        v-if="show_more_options === false && colour_palette_selected == false && fonts_selected == false && modifiers_selected == false && image_uploader_selected == false">New!</span>
    </div>

    <ColourPalette v-if="colour_palette_selected === true" :selected_items="selected_items" :menu="menu"
      :selected_item_details="selected_item_details" v-on:return-to-buttons="returnToButtons()"
      v-on:items-updated="itemsUpdated" />

    <Fonts v-if="fonts_selected === true" :selected_items="selected_items" :menu="menu"
      :selected_item_details="selected_item_details" v-on:return-to-buttons="returnToButtons()"
      v-on:items-updated="itemsUpdated" />

    <ImageUploadPage v-if="image_uploader_selected === true" :selected_items="selected_items" :menu="menu"
      :selected_item_details="selected_item_details" v-on:return-to-buttons="returnToButtons()"
      v-on:items-updated="itemsUpdated" />

  </div>


</template>

<script>
import ColourPalette from '@/components/ColourPalette.vue'
import Fonts from '@/components/Fonts.vue'
import ImageUploadPage from '@/components/ImageUploadPage.vue'
import ItemAlignment from '@/components/dynamic_svgs/ItemAlignment.vue'
import CopyItemsToOtherMenuModal from './CopyItemsToOtherMenuModal.vue'

export default {
  name: 'ItemOptionsRevised',
  props: ['selected_items', 'foods', 'menu', 'languages', 'language', 'relocating_multiple_items'],
  components: {
    ColourPalette,
    Fonts,
    ImageUploadPage,
    ItemAlignment,
    CopyItemsToOtherMenuModal
  },
  data() {
    return {
      selected_item_details: [],
      colour_palette_selected: false,
      fonts_selected: false,
      modifiers_selected: false,
      image_uploader_selected: false,
      image: {},
      componentKey: 0,
      show_more_options: false,
      copy_items_to_other_menu: false,
      url: process.env.VUE_APP_API_URL,
      buttons: [
        {
          name: "Edit item",
          imgUrl: "edit-item.svg",
          method: "editItem()",
          onlyOneSelected: true
        },
        {
          name: "Colour palette",
          imgUrl: "colour-palette-button.svg",
          onlyOneSelected: false
        },
        {
          name: "Fonts",
          imgUrl: "fonts-button.svg",
          onlyOneSelected: false
        },
        {
          name: "Modifiers",
          imgUrl: "modifiers-button.svg",
          onlyOneSelected: true
        },
        {
          name: "Image",
          imgUrl: "image-button.svg",
          onlyOneSelected: true
        },
        {
          name: "Duplicate",
          imgUrl: "duplicate-button.svg",
          onlyOneSelected: true
        },
        {
          name: "Hide/Show",
          imgUrl: "visible-button.svg",
          onlyOneSelected: false
        },
        {
          name: "Delete",
          imgUrl: "delete-button.svg",
          onlyOneSelected: false
        },
      ]
    }
  },
  created() {
    this.retrieveSelectedItemDetails();
  },
  methods: {
    exit() {
      this.$emit('close');
    },
    closeModal() {
      this.copy_items_to_other_menu = false;
    },
    itemsUpdated: function (selected_item_details) {
      this.selected_item_details = selected_item_details
      this.$emit('item-updated-successfully', this.selected_item_details)
      this.selected_item_details = JSON.parse(selected_item_details)
    },
    moreOptionsText() {
      if (this.show_more_options === true) {
        return 'Back'
      } else {
        if (this.colour_palette_selected == false && this.fonts_selected == false && this.modifiers_selected == false && this.image_uploader_selected == false) {
          return "Alignment & bulk sorting"
        }
      }
    },
    relocateMultipleItems() {
      this.$emit('relocate-multiple-items')
    },
    copyItemsToOtherMenu() {
      console.log('copy items to other menu firing from item options revised')
      console.log(`selected items: ${this.selected_items}`)
      this.copy_items_to_other_menu = true;
      // this.$emit('copy-items-to-other-menu')
    },
    toggleAlignment(direction) {
      console.log("TOGGLE ALIGNMENT FIRED")
      let selected_direction = Boolean
      if (direction === 'left') {
        selected_direction = false
      }
      if (direction === 'centre') {
        selected_direction = true
      }
      var selected_items = this.selected_items
      this.$emit('set-to-loading')
      for (let i = 0; i < selected_items.length; i++) {
        console.log(selected_direction)
        this.$http.secured.post(`/app_update_food/` + this.menu.id + '/' + selected_items[i], {
          food: {
            centre_content: selected_direction
          }
        })
          .then(response => {
            console.log(response.data)
            this.selected_item_details[i].centre_content = selected_direction
            this.selected_item_details[0].centre_content = selected_direction
            this.$emit('item-updated-successfully', JSON.stringify(this.selected_item_details[i]))
            this.componentKey++
          })
          .catch(error => this.setError(error, 'Cannot update food.'))
      }
    },
    handleEvent(button_name) {
      if (button_name === "Edit item") {
        this.editItem();
      }
      if (button_name === "Duplicate") {
        this.cloneItem();
      }
      if (button_name === "Delete") {
        this.deleteItem();
      }
      if (button_name === "Hide/Show") {
        this.toggleItemVisibility();
      }
      if (button_name === "Image") {
        this.image_uploader_selected = true
      }
      if (button_name === "Fonts") {
        this.fonts_selected = true
      }
      if (button_name === "Colour palette") {
        this.colour_palette_selected = true
      }
      if (button_name === "Modifiers") {
        window.location.replace(this.url + '/foods/' + this.selected_items[0] + '/add_selections');
      }
    },
    subMenuSelected() {
      if (this.colour_palette_selected === true || this.fonts_selected === true || this.image_uploader_selected === true) {
        return true
      } else {
        return false
      }
    },
    returnToButtons() {
      this.colour_palette_selected = false
      this.fonts_selected = false
      this.modifiers_selected = false
      this.image_uploader_selected = false
    },
    computedButtons() {
      var buttons = this.buttons
      var computedButtons = []
      for (let i = 0; i < buttons.length; i++) {
        if (this.selected_items.length > 1) {
          if (buttons[i].onlyOneSelected != true) {
            computedButtons.push(buttons[i])
          }
        } else {
          computedButtons.push(buttons[i])
        }
      }
      return computedButtons
    },
    retrieveSelectedItemDetails() {
      console.log(`${this.selected_items}`)
      var selected_items = this.selected_items
      var foods = this.foods
      var selected_item_details = []
      for (let i = 0; i < foods.length; i++) {
        if (selected_items.includes(foods[i].id)) {
          selected_item_details.push(foods[i])
        }
      }
      this.selected_item_details = selected_item_details
    },
    editItem() {
      localStorage.snapToItem = `${this.selected_items[0]}`
      this.$store.commit("setAnchoredItem", this.selected_item_details[0].id);
      this.$emit('edit-item', this.selected_items[0])
      console.log(`emitted: ${this.selected_items[0]}`)
    },
    toggleItemVisibility() {
      var selected_items = this.selected_items
      var visibility_setting = this.selected_item_details[0].hidden
      this.$emit('set-to-loading')
      for (let i = 0; i < selected_items.length; i++) {
        this.$http.secured.post(`/app_update_food/` + this.menu.id + '/' + selected_items[i], {
          food: {
            hidden: !visibility_setting
          }
        })
          .then(response => {
            console.log(response.data)
            this.selected_item_details[i].hidden = !visibility_setting
            this.$emit('item-updated-successfully', JSON.stringify(this.selected_item_details))
          })
          .catch(error => this.setError(error, 'Cannot create food.'))
      }
    },
    deleteItem() {
      if (confirm(`Are you sure you want to delete all selected items? Number of items that will be deleted: ${this.selected_items.length}.`)) {
        this.$emit('set-to-loading');
        for (let i = 0; i < this.selected_items.length; i++) {
          this.$http.secured.delete("/app_food_destroy/" + this.selected_items[i])
            .then(response => {
              this.$emit('item-deleted', this.selected_items[i])
            })
            .catch(error => this.setError(error, 'Something went wrong'))
        }
      }
    },
    toggleImageSelector() {
      if (this.image_selector === false) {
        this.image_selector = true
      } else {
        this.image_selector = false
      }
    },
    cloneItem() {
      const value = this.selected_item_details[0]
      this.$emit('set-to-loading');
      this.$store.commit("setAnchoredItem", this.selected_item_details[0].id);
      const self = this;
      var font = ''
      if (this.$store.getters.getSelectedFont != '') {
        font = this.$store.getters.getSelectedFont
      } else {
        font = 'default'
      }

      if (!value) {
        return
      }
      this.$http.secured.post(`/app_clone_food/` + this.selected_item_details[0].id, {
        food: {
          translation_en: this.selected_item_details[0].translation_en,
          translation_ru: this.selected_item_details[0].translation_ru,
          translation_jp: this.selected_item_details[0].translation_jp,
          translation_sp: this.selected_item_details[0].translation_sp,
          translation_fr: this.selected_item_details[0].translation_fr,
          translation_ca: this.selected_item_details[0].translation_ca,
          translation_kr: this.selected_item_details[0].translation_kr,
          translation_de: this.selected_item_details[0].translation_de,
          translation_zh: this.selected_item_details[0].translation_zh,
          translation_nl: this.selected_item_details[0].translation_nl,
          translation_pt: this.selected_item_details[0].translation_pt,
          translation_it: this.selected_item_details[0].translation_it,
          translation_ar: this.selected_item_details[0].translation_ar,
          description_en: this.selected_item_details[0].description_en,
          description_ru: this.selected_item_details[0].description_ru,
          description_jp: this.selected_item_details[0].description_jp,
          description_sp: this.selected_item_details[0].description_sp,
          description_fr: this.selected_item_details[0].description_fr,
          description_ca: this.selected_item_details[0].description_ca,
          description_kr: this.selected_item_details[0].description_kr,
          description_de: this.selected_item_details[0].description_de,
          description_zh: this.selected_item_details[0].description_zh,
          description_nl: this.selected_item_details[0].description_nl,
          description_pt: this.selected_item_details[0].description_pt,
          description_it: this.selected_item_details[0].description_it,
          description_ar: this.selected_item_details[0].description_ar,
          category_header: this.selected_item_details[0].category_header,
          major_category_header: this.selected_item_details[0].major_category_header,
          unit: this.selected_item_details[0].unit,
          price: this.selected_item_details[0].price,
          second_price_number: this.selected_item_details[0].second_price_number,
          third_price_number: this.selected_item_details[0].third_price_number,
          second_unit: this.selected_item_details[0].second_unit,
          third_unit: this.selected_item_details[0].third_unit,
          special_tag_for_item: this.selected_item_details[0].special_tag_for_item,
          orderable: this.orderable,
          spicy: this.selected_item_details[0].spicy,
          vegan: this.selected_item_details[0].vegan,
          spicy_level_two: this.selected_item_details[0].spicy_level_two,
          spicy_level_three: this.selected_item_details[0].spicy_level_three,
          chef_recommended: this.selected_item_details[0].chef_recommended,
          pork: this.selected_item_details[0].pork,
          vegetarian: this.selected_item_details[0].vegetarian,
          halal: this.selected_item_details[0].halal,
          dairy: this.selected_item_details[0].dairy,
          fish: this.selected_item_details[0].fish,
          alcohol: this.selected_item_details[0].alcohol,
          alcohol_free: this.selected_item_details[0].alcohol_free,
          gluten: this.selected_item_details[0].gluten,
          gluten_free: this.selected_item_details[0].gluten_free,
          peanuts: this.selected_item_details[0].peanuts,
          shellfish: this.selected_item_details[0].shellfish,
          egg: this.selected_item_details[0].egg,
          twenty_four_hours: this.selected_item_details[0].twenty_four_hours,
          soy_beans: this.selected_item_details[0].soy_beans,
          walnuts: this.selected_item_details[0].walnuts,
          celery: this.selected_item_details[0].celery,
          mustard: this.selected_item_details[0].mustard,
          sesame_seeds: this.selected_item_details[0].sesame_seeds,
          sulfites: this.selected_item_details[0].sulfites,
          lupin: this.selected_item_details[0].lupin,
          clams: this.selected_item_details[0].clams,
          menu_id: this.menu.id,
          item_name_font: this.selected_item_details[0].item_name_font,
          item_price_font: this.selected_item_details[0].item_price_font,
          item_name_font_colour: this.selected_item_details[0].item_name_font_colour,
          item_description_font: this.selected_item_details[0].item_description_font,
          background_colour: this.selected_item_details[0].background_colour,
          stroke_bottom: this.selected_item_details[0].stroke_bottom,
          sort: this.selected_item_details[0].sort,
          item_name_font_size: this.selected_item_details[0].item_name_font_size,
          container_height: this.selected_item_details[0].container_height,
          item_type: 'food'
        }
      })
        .then(response => {
          // this.$emit('item-cloned-successfully', JSON.stringify(this.selected_item_details))
          this.$emit('reload-menu')
        }).catch(error => this.setError(error, 'Cannot create food.'))
    }
  },
  watch: {
    selected_items() {
      this.retrieveSelectedItemDetails();
    },
    foods() {
      this.retrieveSelectedItemDetails();
    }
  }
}





</script>

<style scoped>
#item-options-revised {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  background-color: white;
  width: 95%;
  max-width: 750px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 20px 0px 20px 0px;
  border-radius: 10px 10px 0px 0px;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.button {
  display: flex;
  flex-direction: column;
  width: 25%;
  align-items: center;
  color: #505050;
  font-weight: 600;
  box-sizing: border-box;
  padding: 10px 5px 10px 5px;
  cursor: pointer;
  margin-top: 10px;
}

.button:hover {
  background-color: #B3CBE7;
  transition: 0.2s;
}

.button img {
  width: 50px;
  height: 50px;
  margin-bottom: 5px;
}

.button p {
  text-align: center;
  margin: 0px 0px 0px 0px;
}

.close-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}

.close-box img {
  width: 20px;
  height: 20px;
  margin-bottom: 25px;
}

.items-selected-list {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 0px 10px 0px 10px;
}

.selected-items-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px 0px 10px 0px;
}

.selected-item {
  background-color: #8CC63E;
  color: white;
  box-sizing: border-box;
  padding: 2.5px 5px 2.5px 5px;
  margin-right: 5px;
  border-radius: 2.5px;
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 2.5px;
  margin-top: 2.5px;
}

.explainer-text {
  font-weight: 600;
  font-size: 12.5px;
}

.explainer-text-small {
  font-size: 10px;
  font-weight: 400;
  color: #505050;
}

.more-options {
  margin: auto;
  width: 100%;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  margin-top: 25px;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  color: #404041;
  position: relative;
}

.new-identifier {
  position: absolute;
  top: -17px;
  background-color: #EDD15D;
  border: 1px solid #B3A259;
  color: black;
  width: max-content;
  font-size: 10px;
  box-sizing: border-box;
  padding: 2.5px;
  border-radius: 2.5px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.alignment-options {
  font-size: 10px;
  font-weight: 400;
  color: black;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 10px;
}

.relocation-options {
  margin-left: 50px;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.relocation-options:hover {
  background-color: #B3CBE7;
  transition: 0.2s;
}

.copy-options {
  cursor: pointer;
}

.sort-button-container {
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(100, 100, 100, 0.7);
  margin: auto;
}

.alignment-buttons-container {
  display: flex;
  flex-direction: row;
  width: 125px;
  justify-content: space-between;
}

.alignment-tag {
  color: #505050;
  font-weight: 600;
}

.extra-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  justify-content: space-evenly;
}

.extra-buttons div {
  display: flex;
  flex-direction: column;
  width: 100px;
  text-align: center;
  font-size: 10px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 5px;
  height: 100px;
  border: 1px solid rgba(0, 0, 0, 0);
  justify-content: center;
}

.extra-buttons div:hover {
  border: 1px solid black;
  transition: 0.2s;
}

@media(max-width: 600px) {
  .button p {
    font-size: 12.5px;
  }
}
</style>