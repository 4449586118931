<template>

  <div class="order-submission-container">


    <div class="info-row" v-if="order_type === 'in-restaurant'">

      <p v-if="menu.menu_type === 'golf course'">Hole number</p>

      <p v-if="menu.menu_type === 'spa'">Location</p>

      <p v-if="menu.menu_type === 'restaurant'">{{ this.$translations.table_number[language] ||
        this.$translations.table_number['en'] }}</p>

      <input v-model="table_number" maxlength="10" :class="{ 'disabled': tableNumberInputDisabled() }" />

    </div>


    <div class="info-row" v-if="order_type === 'in-restaurant'">

      <p>{{ this.$translations.guests[language] || this.$translations.guests['en'] }}</p>

      <div class="guests">

        <div v-for="guest_option in guest_options" :key="guest_option" class="guest" @click="setPax(guest_option)"
          :class="{ 'selected': guests === guest_option }">
          {{ guest_option }}
        </div>

      </div>

    </div>

    <div class="info-row" v-if="order_type === 'pickup' || order_type === 'delivery'">

      <p>Name</p>

      <input v-model="name" maxlength="30" />

    </div>


    <div class="info-row" v-if="order_type === 'pickup' || order_type === 'delivery'">

      <p>Phone number</p>

      <input v-model="phone_number" maxlength="20" @input="validPhoneNumber()" />

    </div>

    <div class="info-row" v-if="order_type === 'delivery'">
      <p>Address</p>
      <vue-google-autocomplete id="address-input" classname="input" v-on:placechanged="getAddressData">
      </vue-google-autocomplete>
    </div>

    <div class="info-row" v-if="order_type === 'in-room'">

      <p>Last name</p>

      <input v-model="last_name" maxlength="30" />

    </div>

    <div class="info-row" v-if="order_type === 'in-room'">

      <p>Room number</p>

      <input v-model="room_number" maxlength="20" @input="validPhoneNumber()" />

    </div>

    <div class="submit-container">
      <transition name="fade">
        <div class="submit" v-if="canSubmit()" @click="submitOrder()">
          {{ this.$translations.submit_order[language] || this.$translations.submit_order['en'] }}
        </div>
      </transition>
    </div>


  </div>



</template>



<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'


export default {
  name: 'OrderUserInfo',
  props: ['order_type', 'language', 'order_rows', 'menu', 'subtotal', 'currency'],
  components: {
    VueGoogleAutocomplete
  },
  data() {
    return {
      table_number: '',
      guest_options: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      guests: Number,
      address: '',
      phone_number: '',
      name: '',
      last_name: '',
      room_number: '',
      uid: '',
      order_id: Number
    }
  },
  beforeCreate() {

  },
  created() {
    this.checkForPendingOrder();
    this.getGuestDetails();
    this.uid = Date.now().toString(36) + Math.random().toString(36).substr(2);
  },
  methods: {
    setPax(guest_option) {
      this.guests = guest_option
    },
    getGuestDetails() {
      if (localStorage.table_number != "null" && localStorage.table_number != "undefined") {
        this.table_number = localStorage.table_number
      }
      if (localStorage.pax != "null" && localStorage.pax != "undefined") {
        this.setPax(localStorage.pax)
      }
    },
    checkForPendingOrder() {
      if (localStorage.pending_order_id != null) {
        this.$emit('resubmitting')
        this.retrievePendingOrder(localStorage.pending_order_id)
      }
    },
    retrievePendingOrder(pendingOrder) {
      this.$http.plain.get("/retrieve_pending_order/" + pendingOrder)
        .then(response => {
          this.order_id = response.data.pending_order_id
          // this.submitOrderRows()
          this.submitOrder();

          // this.$router.replace({ path: `/orders/${response.data.pending_order_id}` })
        })
        .catch(error => setTimeout(() =>
          this.checkForPendingOrder(),
          3000))
    },
    tableNumberInputDisabled() {
      if ((localStorage.table_number_editable === "false") && (localStorage.table_number != '' && localStorage.table_number != "null" && localStorage.table_number != undefined)) {
        return true
      }
    },
    canSubmit() {
      if (this.order_type === 'in-restaurant') {
        if ((this.table_number != '' && this.table_number != undefined) && (this.guests != Number && this.guests != undefined)) {
          return true
        }
      } else if (this.order_type === 'pickup') {
        if (this.validPhoneNumber() && this.name != '') {
          return true
        }
      } else if (this.order_type === 'delivery') {
        console.log('delivery')
        console.log(`order type: ${this.order_type}`)
        console.log(`name: ${this.name}`)
        console.log(`address: ${this.address}`)
        console.log(`phone number: ${this.validPhoneNumber()}`)
        if (this.validPhoneNumber() && this.name != '' && this.address != '') {
          console.log('CAN SUBMIT')
          return true
        }
      } else if (this.order_type === 'in-room') {
        if (this.last_name.length > 3 && this.room_number != '') {
          return true
        }
      }
    },
    checkForRedirect(counter) {
      if (counter === this.order_rows.length) {
        this.$router.push({ path: `/orders/${this.order_id}` })
      }
    },
    submitOrder() {
      var orderRowsPayload = [];
      var id = localStorage.pending_order_id != null ? localStorage.pending_order_id : this.uid;
      var order_session_token = '';
      var session_based_order = false;

      if (this.menu.session_based_ordering === true && localStorage.order_session_token != null) {
        order_session_token = localStorage.order_session_token;
        session_based_order = true;
      }

      for (let i = 0; i < this.order_rows.length; i++) {
        var selectionIds = [];
        var selections_prices = 0;
        for (let s = 0; s < this.order_rows[i].selections.length; s++) {
          selectionIds.push(this.order_rows[i].selections[s].id);
          selections_prices += Number(this.order_rows[i].selections[s].additional_fee);
        }
        let final_price = this.order_rows[i].item_price + selections_prices;
        selectionIds = selectionIds.length ? String(selectionIds) : '';

        orderRowsPayload.push({
          order_row: {
            food: this.order_rows[i].food,
            food_id: this.order_rows[i].food.id,
            item_price: final_price,
            unit: this.order_rows[i].item_unit,
            note: this.order_rows[i].note,
            order_session_key: order_session_token,
            session_based_order_row: session_based_order,
            session_id: id,
            selections: selectionIds
          }
        });
      }

      localStorage.setItem('order_pending', 'y');
      localStorage.setItem('pending_order_id', this.uid);
      this.$emit('submit-order');

      const payload = {
        order: {
          menu_id: this.menu.id,
          currency: this.currency,
          order_type: this.order_type,
          pax: this.guests,
          table_number: this.table_number,
          physical_address: this.address,
          phone_number: this.phone_number,
          last_name: this.last_name,
          name: this.name,
          room_number: this.room_number,
          session_id: this.uid,
          price: this.subtotal,
          order_session_key: order_session_token,
          session_based_order: session_based_order
        },
        order_rows: orderRowsPayload
      };

      this.$http.plain.post('/app_submit_order', payload)
        .then(response => {
          console.log('Order and order rows submitted successfully!');
          this.$store.commit('clearOrder');
          localStorage.removeItem('order');
          localStorage.removeItem('ots');
          localStorage.setItem('table_number', this.table_number);
          this.order_id = response.data.id;
          this.$router.push({ path: `/orders/${this.order_id}` });
        })
        .catch(error => console.log('Failed to submit order', error));
    },

    getAddressData: function (addressData, placeResultData, id) {
      this.address = placeResultData.formatted_address;
    },
    validPhoneNumber() {
      // var regex = new RegExp(/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/);
      // if (regex.test(this.phone_number)) {
      //     return true
      // }
      if (this.phone_number.length > 5 && this.phone_number.length < 20) {
        return true
      }
    },
    getTableNumberPlaceHolder() {
      if (this.language === 'en') {
        return 'Enter your table number'
      } else if (this.language === 'zh') {
        return '填写您的桌号'
      } else {
        return 'Enter your table number'
      }
    },
    getNamePlaceHolder() {
      if (this.language === 'en') {
        return 'Enter your name'
      } else if (this.language === 'zh') {
        return '名称'
      } else {
        return 'Enter your name'
      }
    },
    getPhonePlaceHolder() {
      if (this.language === 'en') {
        return 'Enter your phone'
      } else if (this.language === 'zh') {
        return '如果有什么问题，餐厅会给您打个电话'
      } else {
        return 'Enter your phone'
      }
    },
    getLastNamePlaceHolder() {
      if (this.language === 'en') {
        return 'Last name (on booking)'
      } else if (this.language === 'zh') {
        return '您的姓名'
      } else {
        return 'Last name (on booking)'
      }
    },
    getRoomNumberPlaceHolder() {
      if (this.language === 'en') {
        return 'Enter your room number'
      } else if (this.language === 'zh') {
        return '您的房号'
      } else {
        return 'Enter your room number'
      }
    }
  }
}

</script>

<style scoped>
.order-submission-container {
  margin-top: 50px;
}

input {
  width: 100%;
  height: 50px;
  border-top: 1px solid rgba(0, 0, 0, 0);
  border-left: 1px solid rgba(0, 0, 0, 0);
  border-right: 1px solid rgba(0, 0, 0, 0);
  border-bottom: 1px solid lightgray;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  /* border-radius: 2.5px; */
  /* box-shadow: 0 0 8px rgba(100, 100, 100, 0.1); */
  box-sizing: border-box;
  /* padding: 0px 15px 0px 15px; */
  margin-bottom: 50px;
}

input::placeholder {
  color: lightgray;
}

.info-row p {
  font-weight: 600;
  width: max-content;
  border: 1px solid black;
  box-sizing: border-box;
  padding: 2.5px;
  border-radius: 2.5px;
  font-size: 13.5px;
}

.guests {
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
}

.guest {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 5px;
  color: lightgray;
  border: 1px solid lightgray;
  margin: 0px 10px 10px 0px;
  font-size: 30px;
  border-radius: 5px;
  width: 50px;
  cursor: pointer;
}

.selected {
  background-color: black;
  color: white;
  font-weight: 600;
  border: 1px solid black;
}

.submit-container {
  width: 100%;
  height: 75px;
  margin-top: 50px;
  margin-bottom: 100px;
}

.submit {
  width: 100%;
  height: 75px;
  background-color: black;
  background-image: linear-gradient(to right, rgb(153, 202, 60), rgb(7, 153, 72));
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  font-size: 20px;
  cursor: pointer;
  color: white;
  font-weight: 600;
}

.disabled {
  pointer-events: none;
}
</style>