<template>
  <div id="app">
    <Header
      v-if="signedIn() || this.$store.getters.getCreatorMenu === true || getPath('/login') || getPath('/sign_up') || getPath('/subscribe') || getPath('/dashboard') || getPath('/contact')"
      :user_id="this.$store.getters.getUserId" :key="componentKey" />
    <transition name="fade">
      <Message v-if="$store.getters.getMessage.content != ''" />
    </transition>
    <transition name="fade">
      <div class="home-screen" v-if="$store.getters.getDropdownShown === true"></div>
    </transition>
    <router-view />
    <BackButton :currentUser="currentUser" :key="componentKey" v-if="signedIn()" />
    <CookieBanner />
    <Footer v-if="!getPath('/home') && !getPath('/') && !getPath('/pricing') && !getPath('/features')" />
  </div>

</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import Message from '@/components/Message.vue';
import CookieBanner from '@/components/user/CookieBanner.vue'
import BackButton from '@/components/miscellaneous/BackButton.vue'
import 'primeicons/primeicons.css';
import { EventBus } from './event-bus.js';
const newOrderNotifSound = require("@/assets/orders/to-the-point.mp3");

export default {
  name: 'App',
  components: {
    Header,
    Footer,
    Message,
    CookieBanner,
    BackButton
  },
  data () {
    return {
      url: process.env.VUE_APP_API_URL,
      componentKey: 0,
      currentUser: Number,
      userRegion: String
    }
  },
  mounted() {
    // this.checkForRouteWidget();
    EventBus.$on('clicked', signedIn => {
      this.componentKey += 1;
    });
    if (localStorage.signedIn) {
      this.$http.secured.get('/app_current_user')
        .then(response => { 
          var channel = this.$pusher.subscribe('orders-channel');
          var helpRequestsChannel = this.$pusher.subscribe('help-channel');
          this.currentUser = response.data.user
          this.userRegion = response.data.region
          this.handleRedirect(this.userRegion)
          console.log(this.currentUser)
          console.log(this.userRegion)
          channel.bind(String(this.currentUser), data => {
              console.log(data)
              this.postOrder();
              })  
          helpRequestsChannel.bind(String(this.currentUser), data => {
              console.log(data)
              this.postHelpRequest();
              })
            })
        .catch(error => this.setError(error, 'Something went wrong'))
    }
  },
  methods: {
    handleRedirect(userRegion) {
      const currentSubdomain = window.location.hostname.split('.')[0];
      console.log(`userRegion: ${userRegion}`);
      console.log(`currentSubdomain: ${currentSubdomain}`);
      if (userRegion === 'asia' && currentSubdomain !== 'asia') {
        const domainParts = window.location.hostname.split('.');
        let newHostname;
        if (domainParts.length > 2) {
          domainParts.shift();
          newHostname = domainParts.join('.');
        } else {
          newHostname = window.location.hostname;
        }

        const newUrl = `https://asia.${newHostname}${window.location.pathname}`;
        window.location.href = newUrl;
      }
    },
    getPath(path) {
      if (this.$router.currentRoute.path === path) {
          return true
      } else {
          return false
      }
    },
    signedIn() {
      if (this.$store.getters.getLoggedIn === true || localStorage.signedIn === 'true') {
        return true
      }
    },
    // checkForRouteWidget() {
    //   if (this.$router.currentRoute.path != '/') {
    //     this.$tawkMessenger.hideWidget();
    //     console.log('this is not home')
    //   }
    //   if (this.$router.currentRoute.path === '/') {
    //     this.$tawkMessenger.showWidget();
    //     console.log('this is home')
    //   }
    // },
    postOrder() {
        this.playNewOrderSound();
        console.log('order emmiting')
        EventBus.$emit('order_received', this.orderReceived);
    },
    postHelpRequest() {
        this.playNewOrderSound();
        EventBus.$emit('help_request_received', this.helpRequestReceived);
    },
    playNewOrderSound() {
        var audio = new Audio(newOrderNotifSound); 
        audio.play();
    }
  },
  watch:{
      // $route (to, from){
      //     this.checkForRouteWidget();
      // }
  } 
}



</script>


<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@300;400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;600;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+Pro:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Economica:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sacramento&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:wght@300;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zilla+Slab:wght@300;400;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;700&family=Inter:wght@600&display=swap');

#app {
  min-height: 100%;
}

html {
  min-height: 100%;
}

a {
  text-decoration: none;
  color: black;
}

h2 {
  font-weight: 600;
}

body {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  height: 100%;
}

.step-container {
  height: 50px;
  margin-top: 30px;
  display: flex;
  transition: 0.5s;
}

/* APP WIDE TEXT STYLES */

.main-header {
  font-size: 35px;
  font-weight: 400;
  margin: 0px 0px 0px 0px;
}

.home-screen {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 1000;
    display: flex;
    align-items: stretch;
    background-color: rgba(0, 0, 0, 0.3);
}

.close-modal {
    display: flex;
    flex-direction: row;
    font-size: 10px;
    font-weight: 600;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    margin-bottom: 20px;
    width: 100%;
}

.close-modal img {
    width: 15px;
    height: 15px;
    margin-right: 5px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.filler {
  flex-grow: 1;
}

.button-descriptor {
  display: none;
  color: #404041;
  position: absolute;
  font-family: 'Ubuntu', sans-serif;
  /* color: #6D6E70; */
  font-size: 10px;
  padding: 2.5px;
  background-color: white;
  border-radius: 2.5px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
  text-shadow: 0px 0px 0px rgba(0, 0, 0, 0) !important;
  /* text-shadow: rgb(255, 255, 255) 1px 0 10px; */
  font-weight: 600;
  /* top: 30px; */
  left: 30px;
  /* display: flex; */
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 100000;
}


.font-selector-container {
    display: flex;
    font-family: 'Montserrat', sans-serif;
    /* bottom: 0px; */
    height: max-content;
    /* width: 100%; */
    max-width: 90%;
    width: 90%;
    margin: auto;
    flex-direction: column;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 0px 0px 5px 5px;
    z-index: 10000;
    background-color: white;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    margin-top: 0px;
    margin-bottom: 5px;
    /* position: fixed; */
    /* left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid lightgray; */
    /* position: absolute; */
}

.footer {
  margin-top: 50px;
}

.error {
  position: absolute;
  top: 50px;
  margin: auto;
  background-color: darkred;
  color: white;
  font-size: 12.5px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5px;
}

/* loaders */
.loader {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button-loader {
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: black;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@media(max-width: 700px) {
  h2 {
    margin-bottom: 50px;
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #8CC63E;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

/* slide transitions */

.slide-fade-enter-active {
  transition: all .3s ease;
}
.slide-fade-leave-active {
  transition: all .3s ease;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(10px);
  opacity: 0;
}


/* ACCOUNT INFO STYLES */
.account-info {
    font-weight: 600;
    font-size: 12.5px;
    margin-top: 25px;
    width: 95%;
    margin: auto;
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.account-info img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}



/* OVERLAY STYLES */

.overlay img {
    z-index: 0;
    position: absolute;
    height: 500px;
    object-fit: cover;
    width: 100%;
    /* margin-top: 75px; */
    left: 0;
    top: 0;
    opacity: 0.2;
}

.account-info {
  cursor: pointer;
  width: max-content;
  margin: 0px 0px 0px 2.5%;
}



/* HOMEPAGE STYLES */
.gradient-text {
    background: -webkit-linear-gradient(left, rgb(153, 202, 60), rgb(7, 153, 72));
    background: -o-linear-gradient(right, rgb(153, 202, 60), rgb(7, 153, 72));
    background: -moz-linear-gradient(right, rgb(153, 202, 60), rgb(7, 153, 72));
    background: linear-gradient(to right, rgb(153, 202, 60), rgb(7, 153, 72));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


/* ADMIN USER STYLE */
.admin-user {
    width: max-content;
    margin-left: 5px;
    background-color: #28A8E0;
    color: white;
    font-weight: 600;
    font-size: 10px;
    text-transform: uppercase;
    box-sizing: border-box;
    padding: 2.5px 5px 2.5px 5px;
    border-radius: 2.5px;
}

.new-create-button {
  width: 100%;
  border: 1px solid #27A9E1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #27A9E1;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  font-weight: 600;
  font-size: 15px;
  margin: 0px 0px 0px 0px;
  cursor: pointer; 
}

/* ID Indicator styles */


.id-info {
  position: absolute;
  right: 2.5%;
  top: 5px;
  font-family: "Cabin", sans-serif;
  font-size: 12.5px;
  letter-spacing: 0.5px;

}

.id-info span {
  font-weight: 600;
}


</style>
