<template>

  <div class="container">

    <div v-if="is_loading === true" class="loader">

      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>

    </div>

    <div v-else>


      <div v-if="can_edit">

        <div class="top-form-container">

          <div class="close-box" @click="closeModal()">

            <img src="@/assets/closedialogue.svg" />

          </div>

          <h2 v-if="editing === false">Create an item</h2>

          <h2 v-if="editing === true">Edit item: {{ newFood['translation_' + languages[0]] }}</h2>


          <div id="item-type-selector-container">

            <h3>Choose item type</h3>

            <div class="item-type-selector" @click="selectItemType('food')"
              :class="{ 'item-type-selected': major_category_header === false && category_header === false }">
              <img src="../assets/food_drink.svg" />
              Food or drink
            </div>

            <div class="item-type-selector" @click="selectItemType('major_category_header')"
              :class="{ 'item-type-selected': major_category_header === true && category_header === true }">
              <img src="../assets/maj_cat_header.svg" />
              Category
            </div>

            <div class="item-type-selector" @click="selectItemType('minor_category_header')"
              :class="{ 'item-type-selected': category_header === true && major_category_header === false }">
              <img src="../assets/cat_header.svg" />
              Subcategory
            </div>

          </div>

        </div>



        <div id="item-form-container" :class="{ 'hidden': item_type === '' }">

          <form action="" @submit.prevent="createItem">

            <div class="input-container name-container">

              <div v-for="language in languages" v-bind:key="language">

                <div class="form-label">

                  <p>Item name</p>

                  <div class="form-tag">

                    {{ languageLongForm(language) }}

                  </div>

                </div>

                <input class="input" name="food[name]" :placeholder="languageLongForm(language) + ' name for item'"
                  v-model="newFood['translation_' + language]" ref="focus" />

              </div>

            </div>

            <div class="input-container description-container">

              <div v-for="language in languages" v-bind:key="language">

                <div class="form-label">

                  <p>Item description</p>

                  <div class="form-tag">

                    {{ languageLongForm(language) }}

                  </div>

                </div>

                <input class="input" autofocus autocomplete="off" name="food[description]"
                  :placeholder="languageLongForm(language) + ' name for item'"
                  v-model="newFood['description_' + language]" />

              </div>

            </div>

            <div class="input-container price-container"
              v-if="category_header != true && major_category_header != true">

              <div class="form-label">

                <p>Item price</p>

              </div>

              <input class="input" @input="checkForPrice('item_price')" autofocus autocomplete="off" name="food[price]"
                type="decimal" :placeholder="'Enter a price'" v-model="newFood['price']" />

              <div class="form-label">

                <p>Unit of measurement</p>

              </div>

              <input class="input" autofocus autocomplete="off" name="food[unit]"
                :placeholder="'Enter a unit of measurement (such as “500ml” or “large”)'" v-model="newFood['unit']" />

            </div>


            <div class="show-more-item-options" @click="show_more_options = !show_more_options">

              <p v-if="show_more_options === false">Show more options</p>
              <p v-if="show_more_options === true">Hide Options</p>

            </div>

            <div class="input-container price-container second-price-container"
              v-if="(newFood['price'] != null || newFood['third_price_number'] != null) && show_more_options === true">

              <div class="form-label">

                <p>Second price (optional)</p>

              </div>

              <input class="input" autofocus autocomplete="off" @keyup="checkForPrice('second_price_number')"
                name="food[second_price_number]" type="decimal" :placeholder="'Enter a price'"
                v-model="newFood['second_price_number']" />

              <div class="form-label">

                <p>Unit of measurement</p>

              </div>

              <input class="input" autofocus autocomplete="off" name="food[second_unit]"
                :placeholder="'Enter a unit of measurement (such as “500ml” or “large”)'"
                v-model="newFood['second_unit']" />

            </div>

            <div class="input-container price-container second-price-container"
              v-if="newFood['second_price_number'] != null && show_more_options === true">

              <div class="form-label">

                <p>Third price (optional)</p>

              </div>

              <input class="input" autofocus autocomplete="off" type="decimal" name="food[third_price_number]"
                :placeholder="'Enter a price'" v-model="newFood['third_price_number']" />

              <div class="form-label">

                <p>Unit of measurement</p>

              </div>

              <input class="input" autofocus autocomplete="off" name="food[third_unit]"
                :placeholder="'Enter a unit of measurement (such as “500ml” or “large”)'"
                v-model="newFood['third_unit']" />

            </div>

            <div v-if="show_more_options === true">

              <div class="tagline-container" v-if="category_header != true && major_category_header != true">

                <div class="form-label">

                  <p>Tagline</p>

                </div>

                <input class="input" autofocus autocomplete="off" name="food[special_tag_for_item]"
                  :placeholder="'Such as “50% off!”'" v-model="newFood['special_tag_for_item']" />


              </div>

              <div class="item-tags" v-if="major_category_header === false && category_header === false">

                <div class="form-label">

                  <p>Item icons</p>

                </div>

                <div v-for="tag in tags" :key="tag" class="tag-selector">
                  <input type="checkbox" v-model="newFood[`${tag}`]" />
                  {{ tag.replace(/[_-]/g, " ") }}
                  <img :src="require(`../assets/tag_icons/${tag}.svg`)" />

                </div>

              </div>

              <div class="stroke-selector">
                <input type="checkbox" v-model="newFood.stroke_bottom" />
                <p>Display a border at bottom of item</p>

              </div>

              <div class="orderable-item-toggle" @click="hidden = !hidden" :class="{ 'orderable': hidden === false }">

                <div v-if="hidden === false">This item is visible</div>
                <div v-if="hidden === true">This item is hidden</div>

              </div>


              <div class="orderable-item-toggle" @click="toggleOrderable()" :class="{ 'orderable': orderable === true }"
                v-if="category_header != true && major_category_header != true && newFood['translation_' + languages[0]] != null">

                <div v-if="orderable === true">This item is orderable</div>
                <div v-if="orderable === false">This item is not orderable</div>

              </div>


              <div class="orderable-item-toggle"
                @click="appear_in_order_dashboard_settings = !appear_in_order_dashboard_settings"
                :class="{ 'orderable': appear_in_order_dashboard_settings === true }">

                <div v-if="appear_in_order_dashboard_settings === true">This item appears in order dashboard settings
                </div>
                <div v-if="appear_in_order_dashboard_settings === false">This item does not appear in order dashboard
                  settings</div>

              </div>

              <div class="orderable-item-toggle" @click="toggleHiddenFromTopScrollbar()"
                :class="{ 'orderable': hidden_from_top === false }"
                v-if="category_header == true || major_category_header == true && menu.hide_top_scrollbar === false">

                <div v-if="hidden_from_top === true">This item is hidden from top scrollbar</div>
                <div v-if="hidden_from_top === false">This item is visible from top scrollbar</div>

              </div>

            </div>

            <input type="submit" value="Submit" class="submit-button" />

          </form>

        </div>

      </div>

    </div>

  </div>



</template>


<script>
// import LanguageLongForm from '@/components/LanguageLongForm.vue'
import Header from '@/components/Header.vue'

export default {
  name: 'Food',
  props: ['editing', 'item_target', 'menu', 'languages'],
  components: {
    Header
    // LanguageLongForm

  },
  data() {
    return {
      user_id: 0,
      user_type: '',
      item_type: '',
      food_item: Boolean,
      category_header: Boolean,
      major_category_header: Boolean,
      second_price: false,
      third_price: false,
      description_container: false,
      orderable: true,
      appear_in_order_dashboard_settings: true,
      hidden_from_top: false,
      price_count: 1,
      is_editing: false,
      filled_in_prices_count: 0,
      show_in_top_scrollbar: true,
      error: '',
      newFood: {},
      is_loading: false,
      can_edit: Boolean,
      hidden: false,
      show_more_options: false,
      tags: ["spicy", "spicy_level_two", "spicy_level_three", "chef_recommended", "pork", "vegetarian", "halal", "dairy", "fish", "alcohol", "alcohol_free", "gluten", "gluten_free", "peanuts", "shellfish", "egg", "twenty_four_hours", "soy_beans", "walnuts", "celery", "mustard", "sesame_seeds", "sulfites", "lupin", "clams", "dairy_free", "sustainable_fishing", "vegan"]

    }
  },
  created() {
    // this.scrollToTop();
    if (this.editing === true) {
      this.is_loading = true
      this.getFood();
    }
    if (this.menu.user_id === 0) {
      this.can_edit === true
    } else {
      if (this.$store.getters.getLoggedIn === true) {
        if (this.$props.editing === true) {
          this.getFood();
        } else {
          this.getMenuInfo();
        }
      } else {
        this.$http.plain.post('/check_for_login')
          .then(response => {
            localStorage.token = response.data.access
            localStorage.csrf = response.data.csrf
            localStorage.refresh = response.data.refresh
            this.checkForSession();
            if (this.$props.editing === true) {
              this.getFood();
            } else {
              this.getMenuInfo();
            }
          }).catch(error => 'Didnt work')
      }
    }
    document.activeElement.blur();
  },
  mounted() {
    this.$refs['focus'][0].focus();
    if (this.editing === true) {
      this.$nextTick(() => {
        this.$refs['focus'][0].focus();
      })
    }
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    closeModal() {
      this.$emit('close-item-modal')
    },
    checkForSession() {

      this.$http.secured.get('/app_current_user')
        .then(response => {
          this.$store.commit('setLoggedIn', response.data.user)
          this.$store.commit('setUserType', response.data.user_type)
        }).catch(error => this.setError(error, 'Something went wrong'))
    },
    getMenuInfo() {
      let menu_id = this.$router.currentRoute.params.menu_id;
      this.$http.secured.get('/app_show_menu/' + menu_id)
        .then(response => {
          this.menu = response.data.menu,
            this.languages = response.data.languages,
            this.orderable = true,
            this.canEdit()
          this.is_loading = false
        }).catch(error => this.setError(error, 'Something went wrong'))
    },
    getFood() {
      var target = JSON.parse(this.item_target)
      this.newFood = target
      this.item_type = target.item_type,
        this.orderable = target.orderable,
        this.appear_in_order_dashboard_settings = target.appear_in_order_dashboard_settings,
        this.category_header = target.category_header,
        this.major_category_header = target.major_category_header,
        this.hidden = target.hidden,
        this.is_loading = false
      this.fillInMissingLanguages()
    },
    fillInMissingLanguages() {
      var languages = this.languages
      if (this.newFood.name != '') {
        for (let i = 0; i < languages.length; i++) {
          if (this.newFood['translation_' + languages[i]] === '' || this.newFood['translation_' + languages[i]] === null) {
            this.newFood['translation_' + languages[i]] = this.newFood.name
          }
        }
      }
    },
    canEdit() {
      if (this.$store.getters.getUserType === 'admin' || this.$store.getters.getUserId === this.menu.user_id || this.$store.getters.getUserId === this.menu.admin_1 || this.$store.getters.getUserId === this.menu.admin_2 || this.$store.getters.getUserId === this.menu.admin_3 || this.$store.getters.getUserId === this.menu.admin_4 || this.$store.getters.getUserId === this.menu.admin_5 || (this.$store.getters.getLoggedIn === false && this.menu.user_id === 0 || this.menu.valid_admin_users.includes(this.$store.getters.getUserId))) {
        this.can_edit = true
      } else {
        this.can_edit = false
      }
    },
    selectItemType(type) {
      if (type === 'food') {
        this.major_category_header = false
        this.category_header = false
        this.item_type = type
        this.$nextTick(() => {
          this.$refs['focus'][0].focus();
        })

      } else if (type === 'major_category_header') {
        this.major_category_header = true
        this.category_header = true
        this.item_type = type
        this.$nextTick(() => {
          this.$refs['focus'][0].focus();
        })
      } else if (type === 'minor_category_header') {
        this.major_category_header = false
        this.category_header = true
        this.item_type = type
        this.$nextTick(() => {
          this.$refs['focus'][0].focus();
        })
      }
    },
    checkForPrice(price) {
      if (this.price_count == 1 && this.newFood.price != '') {
        this.filled_in_prices_count = 1
      }
      if (this.price_count == 2 && this.newFood.price != '') {
        this.filled_in_prices_count = 2
      }
      if (this.price_count == 3 && this.newFood.price != '') {
        this.filled_in_prices_count = 3
      }
    },
    addPrice() {
      if (this.price_count < 3) {
        this.price_count++
      }
    },
    removePrice() {
      if (this.price_count > 1 && this.price_count < 4) {
        if (this.price_count == 3) {
          this.third_price_number = ''
        } else if (this.price_count == 2) {
          this.second_price_number = ''
        }
        this.price_count--
      }
    },
    toggleOrderable() {
      if (this.orderable === true) {
        this.orderable = false
        this.orderable_text = 'This item cannot be ordered'
      } else if (this.orderable === false) {
        this.orderable = true
        this.orderable_text = 'This item can be ordered'
      }
    },
    getText() {
      if (this.hidden_from_top === true) {
        // this.hidden_from_top = false
        this.hidden_from_top_text = 'Visible from top scrollbar'
      } else if (this.hidden_from_top === false) {
        // this.hidden_from_top = true 
        this.hidden_from_top_text = 'Hidden from top scrollbar'
      }
      if (this.orderable === true) {
        // this.orderable = false
        this.orderable_text = 'This item cannot be ordered'
      } else if (this.orderable === false) {
        // this.orderable = true 
        this.orderable_text = 'This item can be ordered'
      }

    },
    toggleHiddenFromTopScrollbar() {
      if (this.hidden_from_top === true) {
        this.hidden_from_top = false
        this.hidden_from_top_text = 'Visible from top scrollbar'
      } else if (this.hidden_from_top === false) {
        this.hidden_from_top = true
        this.hidden_from_top_text = 'Hidden from top scrollbar'
      }
    },
    languageLongForm(language) {
      if (language == 'en') {
        return 'English'
      } else if (language == 'ru') {
        return 'Russian'
      } else if (language == 'jp') {
        return 'Japanese'
      } else if (language == 'sp') {
        return 'Spanish'
      } else if (language == 'fr') {
        return 'French'
      } else if (language == 'ca') {
        return 'Catalan'
      } else if (language == 'kr') {
        return 'Korean'
      } else if (language == 'de') {
        return 'German'
      } else if (language == 'zh') {
        return 'Chinese'
      } else if (language == 'nl') {
        return 'Dutch'
      } else if (language == 'pt') {
        return 'Portuguese'
      } else if (language == 'it') {
        return 'Italian'
      } else if (language == 'ina') {
        return 'Indonesian'
      } else if (language == 'ar') {
        return 'Arabic'
      } else if (language == 'gre') {
        return 'Greek'
      } else if (language == 'ro') {
        return 'Romanian'
      }
    },
    createItem() {
      this.is_loading = true;
      const value = this.newFood
      var urlName = ''
      var font = ''
      if (this.editing === true) {
        urlName = 'app_update_food/' + this.menu.id + '/' + this.newFood.id
        font = this.newFood.item_name_font
      } else {
        urlName = 'app_create_food'
        if (this.$store.getters.getSelectedFont != '') {
          font = this.$store.getters.getSelectedFont
        } else {
          font = 'default'
        }
      }
      if (!value) {
        return
      }
      this.$http.secured.post(`/${urlName}/`, {
        food: {
          translation_en: this.newFood.translation_en,
          translation_ru: this.newFood.translation_ru,
          translation_jp: this.newFood.translation_jp,
          translation_sp: this.newFood.translation_sp,
          translation_fr: this.newFood.translation_fr,
          translation_ca: this.newFood.translation_ca,
          translation_kr: this.newFood.translation_kr,
          translation_de: this.newFood.translation_de,
          translation_zh: this.newFood.translation_zh,
          translation_nl: this.newFood.translation_nl,
          translation_pt: this.newFood.translation_pt,
          translation_it: this.newFood.translation_it,
          translation_ar: this.newFood.translation_ar,
          translation_ina: this.newFood.translation_ina,
          translation_gre: this.newFood.translation_gre,
          translation_ro: this.newFood.translation_ro,
          description_en: this.newFood.description_en,
          description_ru: this.newFood.description_ru,
          description_jp: this.newFood.description_jp,
          description_sp: this.newFood.description_sp,
          description_fr: this.newFood.description_fr,
          description_ca: this.newFood.description_ca,
          description_kr: this.newFood.description_kr,
          description_de: this.newFood.description_de,
          description_zh: this.newFood.description_zh,
          description_nl: this.newFood.description_nl,
          description_pt: this.newFood.description_pt,
          description_it: this.newFood.description_it,
          description_ar: this.newFood.description_ar,
          description_ina: this.newFood.description_ina,
          description_gre: this.newFood.description_gre,
          description_ro: this.newFood.description_ro,
          category_header: this.category_header,
          major_category_header: this.major_category_header,
          unit: this.newFood.unit,
          price: this.newFood.price,
          second_price_number: this.newFood.second_price_number,
          third_price_number: this.newFood.third_price_number,
          second_unit: this.newFood.second_unit,
          third_unit: this.newFood.third_unit,
          special_tag_for_item: this.newFood.special_tag_for_item,
          orderable: this.orderable,
          appear_in_order_dashboard_settings: this.appear_in_order_dashboard_settings,
          spicy: this.newFood.spicy,
          spicy_level_two: this.newFood.spicy_level_two,
          spicy_level_three: this.newFood.spicy_level_three,
          vegan: this.newFood.vegan,
          chef_recommended: this.newFood.chef_recommended,
          pork: this.newFood.pork,
          vegetarian: this.newFood.vegetarian,
          halal: this.newFood.halal,
          dairy: this.newFood.dairy,
          fish: this.newFood.fish,
          alcohol: this.newFood.alcohol,
          alcohol_free: this.newFood.alcohol_free,
          gluten: this.newFood.gluten,
          gluten_free: this.newFood.gluten_free,
          peanuts: this.newFood.peanuts,
          shellfish: this.newFood.shellfish,
          egg: this.newFood.egg,
          twenty_four_hours: this.newFood.twenty_four_hours,
          soy_beans: this.newFood.soy_beans,
          walnuts: this.newFood.walnuts,
          celery: this.newFood.celery,
          mustard: this.newFood.mustard,
          sesame_seeds: this.newFood.sesame_seeds,
          sulfites: this.newFood.sulfites,
          lupin: this.newFood.lupin,
          hidden_from_top: this.hidden_from_top,
          clams: this.newFood.clams,
          sustainable_fishing: this.newFood.sustainable_fishing,
          dairy_free: this.newFood.dairy_free,
          menu_id: this.menu.id,
          item_name_font: font,
          hidden: this.hidden,
          stroke_bottom: this.newFood.stroke_bottom,
          item_type: 'food'
        }
      })
        .then(response => {
          // this.$store.commit("setAnchoredItem", response.data.id)
          this.$emit('item-added');
        })
        .catch(error => this.setError(error, 'Cannot create food.'))
    },
    setError(error, text) {
      this.error = (error.response && error.response.data && error.response.data.error) || text
    }
  }
}



</script>


<style scoped>
.container {
  box-sizing: border-box;
  padding: 25px;
  /* min-height: 100vh; */
  width: 100%;
  padding-top: 50px;
  padding-bottom: 125px;
}

.top-form-container {
  width: 90%;
  max-width: 1200px;
  margin: auto;
}

#item-form-container {
  width: 90%;
  max-width: 700px;
  margin: 20px auto 20px auto;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
  background-color: white;
  margin-bottom: 50px;
  /* border-radius: 20px; */
  /* border: 1px solid black; */
  /* border: 1px solid lightgray; */

}

.form-label {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

form {
  margin-bottom: 30px;
}

.form-label p {
  margin: 0px 5px 0px 0px;
  font-size: 14px;
  font-weight: 600;
}

.form-tag {
  display: flex;
  width: max-content;
  border: 1px solid black;
  box-sizing: border-box;
  padding: 2.5px;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  border-radius: 2.5px;
}

.input {
  width: 100%;
  border-bottom: 1px solid lightgray;
  border-top: 0px;
  border-right: 0px;
  border-left: 0px;
  font-size: 15px;
  height: 40px;
  margin: 10px 0px 30px 0px;
  font-family: 'Montserrat', sans-serif;
}

#item-type-selector-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  /* margin-bottom: 50px; */
}

#item-type-selector-container h3 {
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  margin: 10px 0px 10px 0px;
}

.item-type-selector {
  width: 31.5%;
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); */
  border: 1px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  box-sizing: border-box;
  padding: 20px 2.5px 20px 2.5px;
  font-size: 14px;
  border-radius: 10px;
  cursor: pointer;
  flex-direction: column;
  /* background-color: white; */
  /* margin: 20px 0px 0px 0px; */
}

.item-type-selector img {
  height: auto;
  width: 100px;
  margin: 0px 0px 10px 0px;
}

.item-type-selected {
  background-color: #4794CB;
  border: 1px solid #4794CB;
  color: white;
  transition: 0.2s;
}

.item-type-selected img {
  filter: invert(99%) sepia(0%) saturate(5593%) hue-rotate(197deg) brightness(115%) contrast(100%);
}

.input-container {
  margin: 0px 0px 30px 0px;
}

.input-container h3 {
  font-weight: 600;
}

.additional-price-toggle {
  width: 100%;
  background-color: white;
  border: 1px solid lightgray;
  /* box-shadow: 0 0 8px rgba(0, 0, 0, 0.1); */
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  border-radius: 5px;
  margin: 0px 0px 10px 0px;
}

.remove-price-toggle {
  color: #BE1E2D;
  /* font-size: 12.5px; */
  /* padding: 5px 0px 5px 0px; */
  box-shadow: 0 0 0;
  border: 1px solid lightgray;
}

.tagline-container {
  margin-top: 50px;
}

.orderable-item-toggle {
  display: flex;
  width: 100%;
  border: 1px solid #4794CB;
  color: #4794CB;
  cursor: pointer;
  box-sizing: border-box;
  padding: 10px 0px 10px 0px;
  border-radius: 5px;
  justify-content: center;
  align-content: center;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 50px;
}

.orderable {
  background-color: #4794CB;
  color: white;
  transition: 0.2s;
}

input[type="submit"] {
  background-image: linear-gradient(to right, rgb(153, 202, 60), rgb(7, 153, 72));
  cursor: pointer;
  display: flex;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  justify-content: center;
  border: 0px;
  height: 60px;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  margin-top: 50px;
  font-size: 20px;
}

.item-tags {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 35px;
}

.item-tags p {
  width: 100%;
}

.tag-selector {
  display: flex;
  align-items: center;
  width: 50%;
  text-transform: capitalize;
  font-weight: 600;
  margin: 15px 0px 0px 0px;
  font-size: 12.5px;
}

.tag-selector img {
  width: 15px;
  height: 15px;
  margin-left: 5px;
}

.loader {
  background-color: white;
  height: 100vh !important;
  width: 100vw !important;
}

.show-more-item-options {
  width: 100%;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 1px solid black;
  color: black;
  cursor: pointer;
  margin-bottom: 30px;
  border-radius: 2.5px;
}

.show-more-item-options:hover {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
}

.stroke-selector {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.stroke-selector p {
  font-size: 15px;
  font-weight: 600;
}

.modal-toggle {
  cursor: pointer;
}

.close-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}

.close-box img {
  width: 20px;
  height: 20px;
  margin-bottom: 25px;
}

.hidden {
  visibility: hidden;
}

@media(max-width: 700px) {
  .container {
    background-color: white;
    padding: 0px 0px 0px 0px;
    margin-top: 0px;
    padding-top: 30px;
    padding-bottom: 50px;
  }

  #item-form-container {
    padding: 0px;
    width: 90%;
    border: 0px;
  }

  #item-type-selector-container {
    flex-direction: column;
    margin-top: 50px;
  }

  .item-type-selector {
    width: 100%;
    margin-bottom: 20px;
    flex-direction: row;
    justify-content: space-between;
    /* align-items: center;
        align-content: center; */
    padding: 0px 5% 0px 5%;
    box-shadow: 0 0 0;
    border: 1px solid black;
    /* height:  */
  }

  .item-type-selector img {
    height: auto;
    width: 50px;
    margin: 0px 0px 0px 0px;
    /* max-height: 25px; */
    /* margin: 0px 0px 10px 0px; */
  }

  .item-type-selected {
    border: 1px solid #4794CB;
  }
}
</style>