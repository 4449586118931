<template>

  <div class="copy-items-modal">

    <div v-if="loading === true" class="loader">

      <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>

    </div>
    <div v-else class="container">
    
      <div class="close-box" @click="closeModal()" >
        <img src="@/assets/closedialogue.svg" />
      </div>
      <p>Copy selected items to:</p>
      <div v-for="menu in menus" :key="menu.id" class="menu-selector" @click="copyItems(menu.id)">
        <div class="menu-info">
          <span>{{ menu.id }}</span>
          <p>{{ menu.name }}</p>
        </div>
        <i class="pi pi-chevron-circle-right" style="font-size: 12.5px; color: black"></i>
      </div>
    </div>

  </div>


</template>


<script>

export default {
  name: "CopyItemsToOtherMenuModal",
  props: ["selected_items"],
  data() {
    return {
      menus: [],
      loading: true,
      error: null,
    }
  },
  created() {
    this.fetchMenus();
  },
  methods: {
    fetchMenus() {
      console.log('fetching menus');
      this.$http.secured
        .get("app_get_menus_to_copy_to").then((response) => {
          this.menus = response.data;
          this.loading = false;
        }).catch((error) => this.setError(error, "Something went wrong"));
    },
    copyItems(menuId) {
    console.log(`Copying items to menu: ${menuId}`);
    this.$http.secured
      .post(
        `/app_copy_items_to_menu/${menuId}`,
        JSON.stringify({
          items_to_copy: this.selected_items
        })
      )
      .then((response) => {
        console.log(response);
        this.$store.commit('setMessage', { 
            content: `Items copied successfully to menu ${menuId}`
        })
        this.closeModal();
      })
      .catch((error) => {
        console.error(error);
        this.setError(error, "Something went wrong");
      });
  },
    closeModal() {
      this.$emit("close");
    }
  }
}



</script>

<style scoped>

.copy-items-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000; /* Ensure it appears above other elements */
  background-color: white; /* Set a background color */
  padding: 20px; /* Add some padding */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  border-radius: 8px; /* Optional: Add rounded corners */
  width: 90%;
  height: 500px;
  overflow-y: scroll;
}

.container p {
  font-size: 12.5px;
  font-weight: 600;
}

.menu-selector {
  width: 97.5%;
  padding: 10px;
  background-color: white; /* White background */
  color: black; /* Black text */
  border: 1px solid black; /* Simple black outline */
  /* border-radius: 4px; */
  cursor: pointer;
  text-align: center;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.menu-selector:hover {
  background-color: #f0f0f0; /* Slightly darker background on hover */
}

.menu-selector span {
  font-size: 10px;
  font-weight: 600;
  margin-right: 10px;
  box-sizing: border-box;
  padding: 2.5px;
  background-color: purple;
  color: white;
}

.menu-info {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.menu-info p {
  margin: 0;
  font-size: 12.5px;
}

.close-box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  cursor: pointer;
}

.close-box img {
  width: 20px;
  height: 20px;
  margin-bottom: 25px;
}

</style>
